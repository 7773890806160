<template>
  <v-layout column>
    <v-card class="pa-6" color="foreground" elevation="1">
      <v-card-title class="px-0 pt-0">
        Escolha a forma de pagamento
      </v-card-title>

      <v-card-subtitle class="px-0">
        Você pode acessar sua conta LogusPay ou selecione um método de pagamento
        de sua preferência.
      </v-card-subtitle>

      <!-- auth -->
      <v-btn
        class="mt-4 mb-4"
        color="primary"
        large
        block
        disabled
        @click="navigation('/auth')"
      >
        Entrar com conta LogusPay
      </v-btn>

      <!-- payment methods -->
      <h1 class="mb-4 text-body-1"></h1>

      <v-list class="transparent pa-0" nav>
        <v-list-item
          v-for="(item, index) in routes"
          class="accent no-select"
          :key="index"
          :disabled="lockedMethods[item.value]"
          @click="submit(item.value)"
        >
          <v-list-item-avatar
            :color="
              lockedMethods[item.value]
                ? `grey ${$vuetify.theme.dark ? 'darken' : 'lighten'}-2`
                : 'primary'
            "
            rounded
          >
            <v-icon :color="lockedMethods[item.value] ? 'grey' : 'white'">
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>

            <!-- disabled subtitle -->
            <v-list-item-subtitle
              v-if="lockedMethods[item.value]"
              class="text-caption"
            >
              <!-- <span v-if="item.value === 'CARD'">
                Consulte seu analista para liberação.
              </span> -->

              <span v-if="item.value === 'BOLETO'">
                Valor mínimo de R$ 100,00 para Boleto Bancário.
              </span>

              <span v-else-if="item.value === 'TRANSFER'">
                Serviço indisponível para TED/DOC.
              </span>
            </v-list-item-subtitle>

            <!-- awaiting subtitle -->
            <v-list-item-subtitle
              v-else-if="awaitingMethods.includes(item.value)"
              class="text-caption"
            >
              Transação pendente, clique para continuar
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon :color="lockedMethods[item.value] ? 'grey' : 'secondary'">
              mdi-chevron-right
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <loader-hover v-if="loading" />
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { moneyMask, formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      routes: [
        // {
        //   icon: "mdi-credit-card-multiple",
        //   label: "Cartão de Crédito",
        //   value: "CARD",
        // },
        {
          icon: "mdi-rhombus-split",
          label: "Pix",
          value: "PIX",
        },
        // {
        //   icon: "mdi-barcode",
        //   label: "Boleto Bancário",
        //   value: "BOLETO",
        // },
        // {
        //   icon: "mdi-bank-transfer",
        //   label: "Transferência TED/DOC",
        //   value: "TRANSFER",
        // },
        // {
        //   icon: "mdi-pandora",
        //   label: "PicPay",
        //   value: "PICPAY",
        // },
      ],
      dialog: false,
    };
  },

  beforeMount() {
    // prevent access this route with transaction performed
    if (this.transactionPerformed && this.jwtDecoded) {
      this.$router.push({
        path: `/checkout/payment/${this.jwtDecoded.transaction.method.toLowerCase()}`,
      });
    }
  },

  mounted() {
    if(this.routes.length === 1) {
      return this.submit(this.routes[0].value);
    }
  },

  computed: {
    ...mapState([
      "jwtDecoded",
      "transactionPerformed",
      "awaitingMethods",
      "lockedMethods",
    ]),
  },

  methods: {
    ...mapActions(["setCurrent", "setTransactionPerformed"]),

    submit(value) {
      if (this.awaitingMethods.includes(value)) {
        // continue transaction
        const id = this.jwtDecoded.awaitingTransactions.find(
          (e) => e.paymentMethod === value
        ).id;

        this.$root.$emit("continue-transaction", id);
      } else {
        // new transaction
        this.setCurrent(null);
        this.setTransactionPerformed(false);
        this.navigation(`/checkout/payment/${value.toLowerCase()}`);
      }
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    moneyMask,

    formatDate,
  },
};
</script>

<style lang="scss" scoped></style>
